body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.menutop {
  text-align: left;
}

.menutop ul {
  list-style: none;
}

.menutop li {
 display: inline;
 padding: 10px;
}

.menutop a {
  color: #000000;
  text-decoration: none;
}

.menutop a:hover {
  text-decoration: underline;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.App-eachtile{

  background-color: #222;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.game-info, .game-board {
  margin-left: 520px;
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: rgba(25, 2, 9, 0.75);
  height: 100px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: "Ubuntu", sans-serif;
  }
  
  .App {
    background-image: linear-gradient(
        to right,
        rgba(255, 0, 0, 0.52),
        rgba(0, 195, 255, 0.73)
      ),
      url(/static/media/app.6cc8c02a.jpg);
    background-repeat: no-repeat;
    transition: 0.2s ease;
  }
  
  .App.hot {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 16, 16, 0.52),
        rgba(0, 0, 0, 0.73)
      ),
      url(/static/media/hot.7a3a7640.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s ease;
  }
  
  main {
    min-height: 100vh;
    padding: 25px;
  }
  
  .App.cold {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 255, 213, 0.52),
        rgba(0, 0, 0, 0.73)
      ),
      url(/static/media/cold.de148c78.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s ease;
  }
  
  .search-container {
    width: 100%;
    margin: 0 0 75px;
  }
  
  .search-bar {
    color: black;
    font-size: 15px;
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 5px rgba(58, 53, 53, 0.73);
    background: rgba(255, 255, 255, 0.52);
    transition: 0.4s ease;
  }
  
  .search-container .search-bar:focus {
    background-color: white;
  }
  
  .location-container {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 3px 3px rgba(58, 53, 53, 0.73);
  }
  
  .location-container .date {
    color: white;
    font-size: 20px;
    text-align: center;
    text-shadow: 3px 3px rgba(58, 53, 53, 0.73);
  }
  
  .weather-container {
    text-align: center;
  }
  
  .weather-container .temperature {
    color: white;
    position: relative;
    display: inline-block;
    margin: 30px auto;
    padding: 15px 25px;
    font-size: 100px;
    font-weight: 700;
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 3px 3px rgba(58, 53, 53, 0.73);
    text-shadow: 3px 3px rgba(58, 53, 53, 0.73);
  }
  
  .weather-container .weather {
    color: white;
    font-size: 50px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(58, 53, 53, 0.73);
  }
